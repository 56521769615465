import { GoogleLogout } from "react-google-login";
import { appConfig } from "../config.js";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const Logout = () => {
  //handle google authenication
  const onLogout = (response) => {
    localStorage.removeItem("userInfoPreset");
    window.location.reload();
  };

  const onLogoutFailure = (response) => {
    console.log("something wrong!");
  };

  return (
    <GoogleLogout
      clientId={appConfig.clientId}
      render={(renderProps) => (
        <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
          <ExitToAppIcon />
        </button>
      )}
      buttonText="Logout"
      onLogoutSuccess={onLogout}
      onFailure={onLogoutFailure}
    ></GoogleLogout>
  );
};

export default Logout;
