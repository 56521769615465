import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import Home from "./components/Home";
import Login from "./components/Login";
import packageJson from "../package.json";

function App() {
  //set state to track user login info
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (localStorage.getItem("userInfoPreset") !== null) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfoPreset")));
    }
  }, []);

  return (
    <div className="app">
      {userInfo ? (
        <Home userInfo={userInfo} />
      ) : (
        <Login setUserInfo={setUserInfo} />
      )}
      <p style={{ textAlign: "center", width: "100%" }}>
        {`Version ${packageJson.version} - `}
        <a style={{ color: "#000" }} href="https://lilcasoft.ca">
          lilcasoft.ca
        </a>
      </p>
    </div>
  );
}

export default App;
