import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logout from "./Logout";

const HomeFooter = ({ selectedItemId, deleteItem }) => {
  const location = useLocation();
  let navigate = useNavigate();

  return (
    <div className="homeContainer__footerContainer">
      {!["/add_item", "/edit_item"].includes(location.pathname) && (
        <Link to="/add_item">
          <AddIcon />
        </Link>
      )}
      {location.pathname === "/view_item" && (
        <Link
          to={{
            pathname: `/edit_item`,
          }}
        >
          <EditIcon />
        </Link>
      )}
      {["/view_item", "/edit_item"].includes(location.pathname) && (
        <button
          onClick={(e) => {
            let askForDelete = window.confirm(
              "Are you sure to delete this item??"
            );
            if (askForDelete) {
              deleteItem(selectedItemId);
              navigate("/");
            } else {
              //do nothing
            }
          }}
        >
          <DeleteOutlineIcon />
        </button>
      )}
      <Logout />
    </div>
  );
};

export default HomeFooter;
