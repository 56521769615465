const HomeItem = ({ userItem }) => {
  return (
    <span
      data-title={userItem.name}
      className="homeContainer__contentContainer__itemContainer__title"
    >
      {userItem.name}
    </span>
  );
};

export default HomeItem;
