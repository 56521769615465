import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ViewItem = ({ setSelectedItemId }) => {
  const location = useLocation();

  useEffect(() => {
    setSelectedItemId(location.state.item.id);
  }, []);

  return (
    <div className="homeContainer__contentContainer__itemContainer__desc">
      <span className="homeContainer__contentContainer__itemContainer__desc__heading">
        {location.state.item.name}
      </span>
      <div
        dangerouslySetInnerHTML={{
          __html: location.state.item && location.state.item.desc,
        }}
      ></div>
    </div>
  );
};

export default ViewItem;
