import "../css/home.css";
import HomeItem from "./HomeItem";
import { Link } from "react-router-dom";

const HomeContent = ({ userItems }) => {
  return (
    <div className="homeContainer__contentContainer">
      {userItems.length ? (
        userItems.map((item) => (
          <Link
            className="homeContainer__contentContainer__itemContainer"
            key={item.id}
            to={{
              pathname: `/view_item`,
            }}
            state={{ item: item }}
          >
            <HomeItem userItem={item} />
          </Link>
        ))
      ) : (
        <p className="homeAlert homeAlert--danger">No items found!!!</p>
      )}
    </div>
  );
};

export default HomeContent;
