import "../css/home.css";
import { appConfig } from "../config.js";
import HomeHeader from "./HomeHeader";
import HomeContent from "./HomeContent";
import HomeFooter from "./HomeFooter";
import ViewItem from "./ViewItem";
import AddItem from "./AddItem";
import EditItem from "./EditItem";
import axios from "axios";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = ({ userInfo }) => {
  const [userItems, setUserItems] = useState([]);
  const [userItem, setUserItem] = useState("");
  const [reloadItem, setReloadItem] = useState(true); //set it true on first load
  const [selectedItemId, setSelectedItemId] = useState(""); // tracking selected item

  //fetch user items from backend
  const fetchUserItems = async () => {
    if (userInfo) {
      //local test
      // const response = await axios.get(appConfig.apiUrl + '/lee_test')
      // setUserItems(response.data)
      // setReloadItem(false)

      //production api call
      var formData = new FormData();
      formData.append("google_id", userInfo.googleId);
      formData.append("from_user", userInfo.email);

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        crossDomain: true,
      };

      await axios
        .post(appConfig.apiUrl + "/user_items", formData, { headers })
        .then((response) => {
          if (response) {
            //store data in localstorage incase of server gone offline

            if (typeof Storage !== "undefined") {
              localStorage.setItem(
                "userItemsOffline",
                JSON.stringify(response.data)
              );
              setUserItems(response.data);
              localStorage.setItem("userInfoPreset", JSON.stringify(userInfo));
            } else {
              console.log("No Web Storage support....");
            }

            setReloadItem(false);
            console.log(userItem);
          }
        })
        .catch((error) => {
          console.log("Error ========>", error);
          setUserItems(JSON.parse(localStorage.getItem("userItemsOffline")));
          toast.success(
            "Live server is temporary down. You can only view but not add/edit data!",
            {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        });
    }
  };

  //get item by id
  const fetchUserItem = async (itemId) => {
    const response = await axios.get(appConfig.apiUrl + "/item/" + itemId);
    setUserItem(response.data);
  };

  //add new item
  const addItem = async (title, desc) => {
    try {
      var formData = new FormData();
      formData.append("name", title);
      formData.append("desc", desc);
      formData.append("from_user", userInfo.email);

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        crossDomain: true,
      };

      await axios
        .post(appConfig.apiUrl + "/add_item", formData, { headers })
        .then((response) => {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //edit item
  const editItem = async (id, title, desc) => {
    try {
      var formData = new FormData();
      formData.append("id", id);
      formData.append("name", title);
      formData.append("desc", desc);
      formData.append("from_user", userInfo.email);

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        crossDomain: true,
      };

      await axios
        .post(appConfig.apiUrl + "/update_item", formData, { headers })
        .then((response) => {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //delete item
  const deleteItem = async (itemId) => {
    try {
      var formData = new FormData();
      formData.append("id", itemId);

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        crossDomain: true,
      };

      await axios
        .post(appConfig.apiUrl + "/delete_item", formData, { headers })
        .then((response) => {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReloadItem(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (reloadItem) {
      fetchUserItems();
    }
  }, [reloadItem]);

  return (
    <div className="homeContainer">
      <BrowserRouter>
        <HomeHeader
          userInfo={userInfo}
          userItems={userItems}
          setReloadItem={setReloadItem}
        />
        <Routes>
          <Route
            exact
            path="/"
            element={<HomeContent userItems={userItems} />}
          />
          <Route path="/add_item" element={<AddItem addItem={addItem} />} />
          <Route
            path="/view_item"
            element={<ViewItem setSelectedItemId={setSelectedItemId} />}
          />
          <Route
            path="/edit_item"
            element={
              <EditItem
                selectedItemId={selectedItemId}
                userItem={userItem}
                fetchUserItem={fetchUserItem}
                editItem={editItem}
              />
            }
          />
        </Routes>
        <HomeFooter selectedItemId={selectedItemId} deleteItem={deleteItem} />
      </BrowserRouter>

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Home;
