import "../css/home.css";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Avatar from "@material-ui/core/Avatar";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const HomeHeader = ({ userInfo, userItems, setReloadItem }) => {
  const location = useLocation();
  const [searchAccount, setSearchAccount] = useState("");

  const onKeyUpSearch = (e) => {
    document
      .querySelectorAll(".homeContainer__contentContainer__itemContainer")
      .forEach((itemRow, itemKey) => {
        if (searchAccount === "") {
          itemRow.classList.remove("d-none");
          setReloadItem(true);
        } else if (
          itemRow.firstChild
            .getAttribute("data-title")
            .toLowerCase()
            .indexOf(searchAccount.toLowerCase()) !== -1
        ) {
          itemRow.classList.remove("d-none");
        } else {
          itemRow.classList.add("d-none");
        }
      });
  };

  return (
    <div className="homeContainer__headerContainer">
      <div className="homeContainer__headerContainer__searchContainer">
        {location.pathname !== "/" && (
          <Link to="/">
            <ArrowBackIosIcon />
          </Link>
        )}
        {location.pathname === "/" && (
          <>
            <input
              type="text"
              placeholder="Search Account..."
              value={searchAccount}
              onChange={(e) => setSearchAccount(e.target.value)}
              onKeyUp={onKeyUpSearch}
            />
            <SearchIcon />
          </>
        )}
      </div>
      <div className="homeContainer__headerContainer__userContainer">
        <Avatar
          alt={`${userInfo.name} profile image`}
          src={`${userInfo.imageUrl}-s800`}
        />
        <span className="homeContainer__headerContainer__userContainer__name">
          {userInfo.name}
        </span>
      </div>
    </div>
  );
};

export default HomeHeader;
