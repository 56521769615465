import { GoogleLogin } from "react-google-login";
import { appConfig } from "../config.js";
import { useEffect } from "react";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import packageJson from "../../package.json";

const Login = ({ setUserInfo }) => {
  //handle google authenication
  const responseGoogle = async (response) => {
    if (response) {
      await setUserInfo(response.profileObj);
    }
  };

  useEffect(() => {
    responseGoogle();
  });

  return (
    <div className="loginContainer">
      <h1>
        <span style={{ fontWeight: "400" }}>E</span>wallet
      </h1>
      <GoogleLogin
        clientId={appConfig.clientId}
        render={(renderProps) => (
          <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <LockOpenIcon /> Google
          </button>
        )}
        buttonText="Sign In"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        isSignedIn={true}
      />
    </div>
  );
};

export default Login;
