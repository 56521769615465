import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UpdateIcon from "@material-ui/icons/Update";
import { useNavigate } from "react-router-dom";

const EditItem = ({ selectedItemId, userItem, fetchUserItem, editItem }) => {
  let navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const loadItemInput = () => {
    if (userItem) {
      setTitle(userItem.name);
      setDesc(userItem.desc);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!title) {
      alert("Please enter title");
      return;
    } else {
      editItem(selectedItemId, title, desc);
      navigate("/");
    }
  };

  useEffect(() => {
    fetchUserItem(selectedItemId);
  }, []);

  useEffect(() => {
    loadItemInput();
  }, [userItem]);

  return (
    <div className="homeContainer__editContainer">
      <h2 style={{ textAlign: "center", color: "#fff", fontWeight: "600" }}>
        Edit Item
      </h2>
      <form onSubmit={onSubmit}>
        <div className="formGroup">
          <label>Title</label>
          <input
            type="text"
            className="formControl"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="formGroup">
          <label>Description</label>
          <ReactQuill theme="snow" value={desc} onChange={setDesc} />
        </div>
        <button type="submit" className="btn btn--success">
          <UpdateIcon /> Save
        </button>
      </form>
    </div>
  );
};

export default EditItem;
